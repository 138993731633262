import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.t(`CHANGE_STATUS.HEADER.${_ctx.visit.tmpStatus}`),
    class: "change-status",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    onCancel: _ctx.cancel,
    closable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "submit",
        type: "tertiary",
        class: "change-status__submit",
        loading: _ctx.confirmLoading,
        disabled: !_ctx.model.reason,
        onClick: _ctx.changeStatus
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("CHANGE_STATUS.CONFIRM")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "disabled", "onClick"]),
      _createVNode(_component_a_button, {
        class: "change-status__cancel",
        key: "back",
        type: "secondary",
        onClick: _ctx.cancel
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("CHANGE_STATUS.CANCEL")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.t(`CHANGE_STATUS.TEXT.${_ctx.visit.tmpStatus}`, {
          patientFullName: `${_ctx.visit.patients[0].firstName} ${_ctx.visit.patients[0].lastName}`,
          visitTime: _ctx.visit?.from.slice(11, 16),
        })), 1 /* TEXT */),
      _createVNode(_component_a_select, {
        placeholder: _ctx.t('CHANGE_STATUS.REASON_PLACEHOLDER'),
        loading: _ctx.statusReasonsLoading,
        class: "change-status__reason",
        value: _ctx.model.reason,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.reason) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusReasons, (statusReason) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: statusReason,
              value: statusReason
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(`STATUS_REASON.${statusReason}`)), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["placeholder", "loading", "value"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "visible", "onCancel"]))
}