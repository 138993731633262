
import {
  defineComponent,
  ref,
  Ref,
  watch,
  reactive,
  PropType,
  inject,
} from "vue";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { VisitExt, StatusReason } from "../../types";
import { AxiosStatic } from "axios";

interface Model {
  reason: StatusReason | undefined;
}

export const ChangeStatusComponent = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visit: {
      type: Object as PropType<VisitExt>,
      required: true,
    },
  },
  emits: ["after-confirm-success", "after-confirm-cancel", "update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();

    const confirmLoading: Ref<boolean> = ref(false);
    const model: Model = reactive({
      reason: undefined,
    });
    const statusReasonsLoading: Ref<boolean> = ref(false);
    const visible: Ref<boolean> = ref(false);

    const statusReasons: Ref<Array<StatusReason>> = ref([]);

    const getStatusReasons = async () => {
      statusReasonsLoading.value = true;
      try {
        statusReasons.value = await http
          .get(`v1/visits/${props.visit.id}/status-reasons`)
          .then((res): Array<StatusReason> => res.data);
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        statusReasonsLoading.value = false;
      }
    };

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
        if (visible.value == true) {
          statusReasons.value = [];
          getStatusReasons();
        }
      }
    );

    const changeStatus = async () => {
      confirmLoading.value = true;

      try {
        await http.put(`v1/visits/${props.visit.id}/status`, {
          reason: model.reason,
          status: props.visit.tmpStatus,
        });
        visible.value = false;
        confirmLoading.value = false;
        notification.open({
          message: t("CHANGE_STATUS.SUCCESS"),
          class: "success",
        });
        emit("after-confirm-success");
      } catch (err: any) {
        showErrors(err.response?.data);
      }
    };
    const cancel = () => {
      emit("update:modelValue", false);
      emit("after-confirm-cancel");
      model.reason = undefined;
      visible.value = false;
    };

    return {
      confirmLoading,
      changeStatus,
      cancel,
      model,
      visible,
      statusReasonsLoading,
      statusReasons,
      t,
    };
  },
});

export default ChangeStatusComponent;
